/******* screen-large.less *******/
/******* mixins.less 2013-1-16 *******/
.button {
  background-color: #93acc6;
  font-size: 12px;
  line-height: 1.33333333;
  font-weight: bold;
  text-transform: uppercase;
  letter-spacing: 0.1em;
  cursor: pointer;
  color: #fff;
  padding: 8px 40px;
  -webkit-appearance: none;
  border-radius: 4px;
  box-sizing: border-box;
  text-align: center;
  text-decoration: none;
}
.button:hover,
.button:focus {
  color: #fff;
  background-color: #b4c6d8;
}
.clearfix {
  *zoom: 1;
}
.clearfix:before,
.clearfix:after {
  display: table;
  content: '';
}
.clearfix:after {
  clear: both;
}
/******* hide-mobile.less 2013-1-16 *******/
#mobile-navi,
.togglenavigation,
.cb-mobile-access,
.cb-mobile-navigation,
.body-mobile {
  display: none;
}
.cb-mobile-navigation {
  opacity: 0;
  -o-transition: none;
  -moz-transition: none;
  -webkit-transition: none;
  transition: none;
}
.cb-mobile-invisible,
.body-non-mobile {
  display: block;
}
/******* navigation-large.less 2013-1-16 *******/
.navi,
.navi > .item,
.dock {
  display: block;
  float: left;
}
.menu {
  display: block;
}
/* LEVEL 1 */
div.sub1 {
  position: absolute;
  left: -3px;
  top: 0;
  z-index: 4;
  width: calc(100% + 6px);
}
.sub1 > .item {
  margin-right: 3px;
  margin-left: 3px;
  max-width: calc((100% / 5) - 6px);
  position: relative;
}
div.sub1 .menu {
  display: block;
  background: #fff;
  text-transform: uppercase;
  font-size: 18px;
  width: 200px;
  max-width: 100%;
  box-sizing: border-box;
  line-height: 20px;
  padding: 15px 10px;
  text-align: center;
}
@media (max-width: 1023px) {
  div.sub1 .menu {
    font-size: 15px;
  }
}
div.sub1 .menu:hover,
div.sub1 .menu:focus {
  background: #93acc6;
  color: #fff;
}
div.sub1 a.path {
  background: #93acc6;
  color: #fff;
}
div.sub2 {
  position: absolute;
  left: 0;
  top: 100%;
  width: 100%;
  background: #fff;
  padding: 10px 0;
  display: none;
  z-index: 5;
}
div.sub2 > .item {
  width: 100%;
}
div.sub2 > .item:hover > .menu,
div.sub2 > .item:focus > .menu {
  color: #000;
  background: none;
}
div.sub2 .menu {
  text-transform: none;
  padding: 4px 10px;
  text-align: left;
  background: none;
  color: #93acc6;
}
div.sub2 .menu:hover,
div.sub2 .menu:focus,
div.sub2 .menu.path {
  color: #000;
  background: none;
}
.cbe--edit-navigation div.sub1 .item.path div.sub2,
div.sub1 .item:hover div.sub2 {
  display: block;
}
/******* layout-large.less 2013-1-16 *******/
.desk {
  max-width: 94%;
}
#home {
  right: 3%;
  top: 70px;
  width: 110px;
}
#view div.seam:hover div.pict a:after {
  top: 0;
  height: 100%;
}
.area {
  width: 100%;
}
.area > .unit {
  margin-right: 0%;
  margin-left: 0%;
  width: 100%;
}
.area h2,
.area .foot {
  margin-right: 1%;
  margin-left: 1%;
}
.area .part,
.area > .grid table {
  margin-right: 1%;
  margin-left: 1%;
  width: 98%;
}
.area .tiny {
  width: 48%;
}
.area > .slim {
  width: 50%;
}
.area > .slim h2,
.area > .slim .foot,
.area > .slim .part,
.area > .slim.grid table {
  margin-right: 2%;
  margin-left: 2%;
}
.area > .slim .part,
.area > .slim.grid table {
  width: 96%;
}
.area > .slim .tiny {
  width: 46%;
}
.cb-layout2 .main {
  width: 100%;
}
.cb-layout2 .main > .unit {
  margin-right: 0%;
  margin-left: 0%;
  width: 100%;
}
.cb-layout2 .main h2,
.cb-layout2 .main .foot {
  margin-right: 1%;
  margin-left: 1%;
}
.cb-layout2 .main .part,
.cb-layout2 .main > .grid table {
  margin-right: 1%;
  margin-left: 1%;
  width: 98%;
}
.cb-layout2 .main .tiny {
  width: 23%;
}
.cb-layout2 .main > .slim {
  width: 25%;
}
.cb-layout2 .main > .slim h2,
.cb-layout2 .main > .slim .foot,
.cb-layout2 .main > .slim .part,
.cb-layout2 .main > .slim.grid table {
  margin-right: 4%;
  margin-left: 4%;
}
.cb-layout2 .main > .slim .part,
.cb-layout2 .main > .slim.grid table {
  width: 92%;
}
.cb-layout2 .main > .slim .tiny {
  width: 92%;
}
.unit.form .tile .name,
.unit a.capt {
  float: left;
  width: 33%;
}
.unit.form .tile .chop,
.unit.form .tile textarea,
.unit.form .tile input.text,
.unit.form .tile .ctrl,
.unit input.capt {
  float: right;
  width: 63%;
}
/*.unit {
  margin-top: 10px;
  margin-bottom: 10px;
}*/
.main {
  width: 102.04081633%;
  margin-left: -1.02040816%;
}
.cb-layout2 .main {
  width: 102.04081633%;
  margin-left: -1.02040816%;
}
.cb-layout1 div.main div.seam {
  margin-left: 1%;
  margin-right: 1%;
}
.cb-layout1 div.main div.seam.wide {
  width: 98%;
}
.cb-layout1 div.main div.seam.slim {
  width: 48%;
}
.cb-layout2 div.main div.seam {
  margin-left: 1%;
  margin-right: 1%;
}
.cb-layout2 div.main div.seam.wide {
  width: 98%;
}
.cb-layout2 div.main div.seam.slim {
  width: 23%;
}
.cb-layout1 div.main div.flat.wide .part.tiny {
  width: 18%;
}
#disp {
  left: 50%;
  margin-left: -175px;
  width: 350px;
  font-size: 15px;
  font-size: 1.5rem;
  top: 120px !important;
}
#disp.zoom {
  left: 50%;
  margin-left: -625px;
  width: 1250px;
}
#disp.tube {
  left: 50%;
  margin-left: -330px;
  width: 660px;
}
#disp.site > .body {
  overflow: auto;
  max-height: 400px;
}
/*#disp.site li,
div.cb-sitemap li {
  .font-size(14); 
}*/
h1,
h2 {
  overflow-wrap: normal;
  word-wrap: normal;
  -webkit-hyphens: none;
  -moz-hyphens: none;
  -ms-hyphens: none;
  hyphens: none;
}
/* scan-large.less 2013-1-16 */
.scan li,
.scan li > .cb-hybrid,
.scan span,
.same > .cb-hybrid {
  padding: 0 0.3em;
}
th.prev,
th.next {
  font-weight: normal;
}
.scan th.prev,
.scan td.prev {
  text-align: left;
}
.scan th.next,
.scan td.next {
  text-align: right;
}
.same,
.this .same,
.same > .cb-hybrid {
  background: #93acc6;
  color: #fff;
}
.same,
.mese .same > span {
  background-color: #93acc6;
}
.this .same,
.this .same > .cb-hybrid,
.mese.this .same > span {
  background-color: #7292b4;
}
.mese .same {
  background: none;
}
/******* module-album-large.less 2013-1-16 *******/
.cb-manual > .head > h2 {
  padding-right: 40px;
  min-height: 24px;
}
.aspt,
.cb-sort-mode {
  font-size: 20px;
  font-size: 2rem;
  display: inline-block;
  padding: 0.12em 1em;
  min-height: 1.2em;
  border: 1px solid;
  border-color: #829fbd #6186ac #6186ac #829fbd;
  border-radius: 2px;
  background-color: #93acc6;
  color: #fff;
  text-align: center;
  text-decoration: none;
  text-shadow: 0 1px 0 rgba(84, 121, 159, 0.75);
  line-height: 1.2em;
  float: left;
  text-shadow: none;
}
.aspt:hover,
.cb-sort-mode:hover,
.aspt:focus,
.cb-sort-mode:focus {
  border-color: #829fbd #6186ac #6186ac #829fbd;
  background-color: #9db4cb;
  color: #fff;
  text-decoration: none;
  cursor: pointer;
}
.aspt:active,
.cb-sort-mode:active {
  outline: none;
  border-color: #829fbd #6186ac #6186ac #829fbd;
  background-color: #7292b4;
  -webkit-box-shadow: 0 1px 1px rgba(0, 0, 0, 0.1) inset;
  box-shadow: 0 1px 1px rgba(0, 0, 0, 0.1) inset;
  color: #fff;
  text-shadow: none;
}
.aspt {
  margin-right: 0.5em;
}
/*# sourceMappingURL=./screen-large.css.map */